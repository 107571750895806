import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { closeNotification } from "../../reducers/user";
import "./notification.sass";
import { throttle } from "lodash";
import closeButton from "../../images/icons/modal_close.svg";

const Notification = ({
  notification,
  user,
  closeNotification,
  fullWidth,
  isAuthenticated,
  ...props
}) => {
  useEffect(() => {
    setCssHeight();
    window.addEventListener("resize", throttle(setCssHeight, 1000));
    return () => {
      window.removeEventListener("resize", setCssHeight);
    };
  });

  const notificationRef = useRef(null);
  const [okToShow, setOkToShow] = useState(true);
  const setCssHeight = () => {
    document.documentElement.style.setProperty(
      "--notification-height",
      `${notificationRef.current ? notificationRef.current.clientHeight : 0}px`
    );
  };

  const close = () => {
    setOkToShow(false);
    document.documentElement.style.setProperty("--notification-height", `0px`);
    if (isAuthenticated) {
      closeNotification(notification._id);
    }
  };

  const renderButton = () => {
    if (notification.persistence === 0) {
      return (
        <button onClick={close}>
          <img src={closeButton} alt="x close icon" />
        </button>
      );
    }
  };
  const returnLevelName = () => {
    switch (notification.level) {
      case 5:
        return "extreme";
      case 4:
        return "urgent";
      case 3:
        return "warning";
      case 2:
        return "normal";
      case 1:
        return "info";
      default:
        return "idc";
    }
  };
  if (notification.autoHide) {
    setTimeout(() => {
      setOkToShow(false);
      //? this is where you will change the auto close delay
    }, 30000);
  }
  const userCheck = () => {
    if (user && user.hasOwnProperty("closedNotifications")) {
      return user.closedNotifications.includes(notification._id);
    } else {
      return false;
    }
  };
  if (notification.live && !userCheck() && okToShow) {
    return (
      <>
        <div
          ref={notificationRef}
          className={`notification wrapper ${returnLevelName()} ${
            fullWidth ? "full" : ""
          }`}
        >
          <div className="content notification__body">
            {renderButton()}
            <p>{notification.message}</p>
          </div>
        </div>
        <div className="spacer" />
      </>
    );
  } else {
    return "";
  }
};
const mapStateToProps = (state) => ({
  user: state.user.currentUser,
  isAuthenticated: state.user.isAuthenticated,
});

export default connect(mapStateToProps, {
  closeNotification,
})(Notification);
